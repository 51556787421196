import React from "react"

import SEO from "../components/seo"

import Mesas from "../components/products/mesas"

const ArmarioEBancadas = () => (
  <>
    <SEO title="Mesas" />

    <Mesas />
  </>
)

export default ArmarioEBancadas
